/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 16-nov-2021, 10:32:09
    Author     : Chus
*/

.paginador{
    text-align: center;
    font-weight: 700;
}

.paginador p span.seleccionada{
    border: 2px solid #252525;
    padding: 0.2em 0.5em;
    font-weight: 700;
    background: #252525;
    color: #ffffff;
}

.paginador p span{
    border: 2px solid #252525;
    padding: 0.2em 0.5em;
    font-weight: 700;
    background: #ffffff;
    color: #252525;
}
.tipo-empresa{
  transition: 0.5s;
}

.tooltip-nowrap .tooltip-inner {
    max-width: none;
    white-space: nowrap;
}

[data-bs-toggle="tooltip"] {
  border-bottom: 1px dotted;
}

.bg-gradient-jotaeme {
    background-color: #0069a3;
    background-image: linear-gradient(180deg,#0069A3 10%,#78d2c4 100%);
    background-size: cover
}

.bg-jotaeme {
    background-color: #0069a3;
    background-size: cover
}

table.listado-rondas th{
  font-size: 0.65em;
  white-space: normal;
}
table.listado-rondas .moneda{
  font-size: 0.48em;
}
.ss-main .ss-single-selected{
  height: calc(1.5em + 0.75rem + 2px);
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
}
.form-check-input{
    position: inherit !important;
    margin: revert !important;
}
/*
.table-hover tbody tr:hover {
    color: #858796;
    background-color: rgba(0, 0, 0, 0.075);
}
*/
.table-hover tbody tr:hover {
    background-color: transparent !important;
}


/*
.table-hover tbody > tr:hover {
    color: #858796;
    background-color: rgba(0, 0, 0, 0.075);
}
.table-hover tbody tr.detail-view:hover table.table {
    color: #5a5c69 !important;
}
.table-hover .table-hover tbody > tr:hover{
    color: #5a5c69 !important;
}

 */